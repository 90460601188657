import React, { useState } from 'react';

import { PasswordInputControlProps } from './password-input-control-type';

import { InputControl } from '../input-control';

import styles from './password-input-control-component.module.css';

export const PasswordInputControl: React.FC<PasswordInputControlProps> = ({
    disablePattern=false,
    hint,
    ...rest
}: PasswordInputControlProps) => {
    // 'doit contenir au moins 10 caractères dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.';
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+=/_~,;:\\]).{10,}$/g;
    const defaultHint = '';

    const [type, setType] = useState('password');

    return (
        <React.Fragment>
            <div className={styles.container}>
                <InputControl
                    className={styles.input}
                    type={type}
                    pattern={!disablePattern ? pattern : undefined}
                    hint={hint || defaultHint}
                    noPaste
                    {...rest}
                />
                <button
                    className={styles.toggleButton}
                    type="button"
                    onClick={() => {
                        if (type === 'password') {
                            setType('text');
                        } else {
                            setType('password');
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
};
